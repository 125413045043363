import React from "react"

import useStoryblok from "../lib/storyblok"

import GlobalLayout from "../components/layout/GlobalLayout"
import { RenderGlobalComponents } from "../globalComponents/GlobalDynamicComponent"
import { RenderComponents } from "../components/DynamicComponent"
import Seo, { MapSEODefaults } from "../components/internal/seo/Seo"
import NoIndexUnlessProduction from "../components/internal/noIndexUnlessProduction/NoIndexUnlessProduction"

function TopicPage({pageContext, location}:PageTemplateProps):JSX.Element {
  let story:any = pageContext ? pageContext.story : undefined
  let template:any = pageContext ? pageContext.template : undefined
  story = useStoryblok(story, location)

  let templateHeaderComponents:Array<JSX.Element> = []
  let templateFooterComponents:Array<JSX.Element> = []
  let pageContent:Array<JSX.Element> = []

  if(story && story.content && story.content.page_content) {
    pageContent = RenderComponents(story.content.page_content, story)
  }
  if(template && template.id) {
    let templateContent = JSON.parse(template.content)
    if(templateContent.header_items) {
      templateHeaderComponents = RenderGlobalComponents(templateContent.header_items, false, location?.pathname)
    }
    if(templateContent.footer_items) {
      templateFooterComponents = RenderGlobalComponents(templateContent.footer_items, false, location?.pathname)
    }
  } else if (template && template.header_items) {
    templateHeaderComponents = RenderGlobalComponents(template.header_items, false, location?.pathname)
    templateFooterComponents = RenderGlobalComponents(template.footer_items, false, location?.pathname)
  }

  // SEO
  let seoData = MapSEODefaults(story)

  return (
    <GlobalLayout>
      <NoIndexUnlessProduction />
      <Seo seoData={seoData} />
      {templateHeaderComponents}
      {pageContent}
      {templateFooterComponents}
    </GlobalLayout>
  )
}

export default TopicPage